import { type RefObject, useState, useRef } from 'react';
import { useOutside } from '../use-outside';

type UseCollapse<TRef = HTMLDivElement> = () => {
  isCollapsed: boolean;
  collapseRef: RefObject<TRef>;
  collapse: () => void;
  expand: () => void;
  toggleCollapse: () => void;
};
export const useCollapse: UseCollapse = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const collapseRef = useRef(null);

  const collapse = () => setIsCollapsed(true);

  const expand = () => setIsCollapsed(false);

  const toggleCollapse = () => {
    setIsCollapsed((isCollapsed) => !isCollapsed);
  };

  useOutside([collapseRef], () => {
    if (collapseRef.current && !isCollapsed) {
      toggleCollapse();
    }
  });

  return {
    collapse,
    expand,
    toggleCollapse,
    isCollapsed,
    collapseRef,
  };
};
