/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useEffect } from 'react';

export const useOutside = (
  refs: RefObject<any> | RefObject<any>[],
  onOutside: (event?: MouseEvent) => void,
  disable?: boolean,
) => {
  useEffect(() => {
    if (disable) return;
    function handleClickOutside(event: MouseEvent) {
      const { target } = event;

      const refsArr = Array.isArray(refs) ? refs : [refs];
      let isOutside = true;

      refsArr.forEach((ref) => {
        if (ref.current?.contains(target)) isOutside = false;
      });
      if (isOutside) {
        onOutside(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, onOutside, disable]);
};
