import { useUserDetails } from '@y2/auth-common/user-details-query';
import { createProjectPageUrl } from '../../services/treedis/treedis.utils';
import { useRouter } from 'next/router';
import { DEFAULT_ZOOM } from '../../components/react-map-gl/consts';

const MAP_COMPONENTS_TYPES = ['map', 'map_side_feed'];

const isMapComponentType = (componentType: string) =>
  MAP_COMPONENTS_TYPES.includes(componentType);

export const useProjectUrl = (
  slug: string,
  options: Record<string, string>,
) => {
  const { query } = useRouter();
  const { data: user } = useUserDetails();

  const projectUrl = createProjectPageUrl(slug, {
    UserID: user?.id || '',
    Pagination: (query?.page as string) || '1',
    MapZoom: isMapComponentType(options.ComponentType)
      ? (query?.zoom as string) || DEFAULT_ZOOM.toString()
      : 'null',
    OpenedFrom: 'feed',
    OpenedFromPageType2: 'null',
    OpenedFromSection: 'yad1',
    Location: '999',
    Spot: 'standard',
    Priority: '0',
    Tag1: 'null',
    Tag2: 'null',
    Tag3: 'null',
    ...options,
  });

  return projectUrl;
};
