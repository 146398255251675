import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

type NativeSpan = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

type Props = NativeSpan &
  SkeletonProps & {
    className?: string;
    count?: number;
    circle?: boolean;
  };

export const Ghost = ({
  className,
  count,
  circle,
  ...otherAttributes
}: Props) => (
  <Skeleton
    className={className}
    count={count}
    circle={circle}
    {...otherAttributes}
  />
);
