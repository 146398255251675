import { ComponentProps, useEffect, useState } from 'react';
// eslint-disable-next-line custom-rules/is-mobile-package-restrictions
import mobile from 'is-mobile';
import Link from 'next/link';
import { format } from 'url';

type Props = ComponentProps<typeof Link>;

export const UnresponsiveLink = ({ href, ...props }: Props) => {
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    setIsMobile(mobile());
  }, []);

  return (
    <a
      target={isMobile ? undefined : '_blank'}
      rel={isMobile ? undefined : 'noreferrer'}
      {...props}
      href={format(href)}
    />
  );
};
