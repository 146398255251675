export const CLIENT_QUERY_KEYS = {
  PROPERTY_TYPE: 'propertyType',
  DEAL_TYPE: 'category',
  TOP_AREA: 'topArea',
  AREA: 'area',
  CITY: 'city',
  HOOD: 'neighborhood',
  MIN_ROOMS: 'minRooms',
  MAX_ROOMS: 'maxRooms',
  BOUNDS: 'bBox',
  PAGE: 'page',
  DEVELOPER: 'company',
} as const;

export const DEAL_TYPES = {
  COMMERCIAL: 'commercial',
  SALE: 'sale',
  RENT: 'rent',
};

export const SERVER_ONLY_KEYS = {
  LIMIT: 'limit',
  SALE: 'sale',
};

export const SEARCH_RESET_PARAMS = [
  CLIENT_QUERY_KEYS.PROPERTY_TYPE,
  CLIENT_QUERY_KEYS.DEAL_TYPE,
  CLIENT_QUERY_KEYS.TOP_AREA,
  CLIENT_QUERY_KEYS.AREA,
  CLIENT_QUERY_KEYS.CITY,
  CLIENT_QUERY_KEYS.HOOD,
  CLIENT_QUERY_KEYS.MIN_ROOMS,
  CLIENT_QUERY_KEYS.MAX_ROOMS,
  CLIENT_QUERY_KEYS.PAGE,
];

export const GEO_QUERY_PARAMS = [
  CLIENT_QUERY_KEYS.TOP_AREA,
  CLIENT_QUERY_KEYS.AREA,
  CLIENT_QUERY_KEYS.CITY,
  CLIENT_QUERY_KEYS.HOOD,
] as const;

export type ClientKeys =
  (typeof CLIENT_QUERY_KEYS)[keyof typeof CLIENT_QUERY_KEYS];

export const VALID_DEVELOPERS_QUERY_PARAMS: string[] = [
  ...GEO_QUERY_PARAMS,
  CLIENT_QUERY_KEYS.PAGE,
];

export const VALID_GRAY_PROJECTS_QUERY_PARAMS: string[] = [
  CLIENT_QUERY_KEYS.PAGE,
  CLIENT_QUERY_KEYS.CITY,
  CLIENT_QUERY_KEYS.HOOD,
];
