import { TextTag } from '../../text-tag/text-tag';
import styles from './item-tags.module.scss';
import { Icon } from '@y2/deprecated/ui/icons/types';
import cn from 'classnames';
import React from 'react';

export type ItemTag = {
  icon?: Icon;
  theme: 'white' | 'dark';
  text: string;
};

type Props = {
  className?: string;
  tags: ItemTag[];
};

export const ItemTags = ({ className, tags }: Props) => {
  return (
    <div className={cn(styles.itemTagsBox, className)}>
      {tags.map((tag) => {
        const { icon: Icon } = tag;
        return (
          <TextTag key={tag.text} theme={tag.theme}>
            {Icon && <Icon className={styles.icon} />}
            {tag.text}
          </TextTag>
        );
      })}
    </div>
  );
};
