import styles from './text-tag.module.scss';
import cn from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  theme?: 'white' | 'dark';
  children: React.ReactNode;
};

export const TextTag = ({ className, children, theme = 'white' }: Props) => {
  return (
    <span className={cn(styles.textTag, styles[theme], className)}>
      {children}
    </span>
  );
};
