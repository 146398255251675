import { UnresponsiveLink } from '@y2/mango/components/unresponsive-link/unresponsive-link';
import { LikeButton } from '@y2/favorites/react-query/components/like-button';
import styles from './feed-item.module.scss';
import Image from 'next/image';

type Props = {
  title: string;
  subTitle: string;
  bottomLabel: string;
  href: string;
  likeId: string;
  projectLogo?: string;
  imageSlot: React.ReactNode;
  onLike?: () => void;
};

export const FeedItem = ({
  title,
  subTitle,
  bottomLabel,
  href,
  imageSlot,
  likeId,
  onLike,
  projectLogo,
}: Props) => {
  return (
    <div className={styles.feedItemBox}>
      <UnresponsiveLink href={href}>
        <div className={styles.imageBox}>{imageSlot}</div>
        <div className={styles.contentBox}>
          <div className={styles.titlesBox}>
            <h3 className={styles.title}>{title}</h3>
            <span className={styles.secondaryTitle}>{subTitle}</span>
          </div>
          {projectLogo && (
            <div className={styles.logoBox}>
              <Image
                fill
                alt={title}
                className={styles.logo}
                src={projectLogo}
              />
            </div>
          )}
        </div>
        <div className={styles.bottomBox}>
          {bottomLabel && (
            <span className={styles.bottomLabel}>{bottomLabel}</span>
          )}
        </div>
      </UnresponsiveLink>
      {/* <div className={styles.likeBox}>
        <LikeButton
          iconClassName={styles.likeIcon}
          token={likeId}
          color="white"
          onClick={onLike}
        />
      </div> */}
    </div>
  );
};
