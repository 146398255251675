import { YAD2_TREEDIS_URL } from '../feed/consts';
import pickBy from 'lodash/pickBy';

export const createProjectPageUrl = (
  id: string,
  options: Record<string, string>,
) => {
  const projectPageURL = new URL(`${YAD2_TREEDIS_URL}/project/${id}`);

  projectPageURL.search = new URLSearchParams(pickBy(options)).toString();
  return projectPageURL.toString();
};
