import styles from './item-image.module.scss';
import Image from 'next/image';
import cn from 'classnames';
import React, { useState } from 'react';
import { Ghost } from '@y2/mango/components/ghost';

type Props = {
  alt: string;
  mainImage: string;
  className?: string;
  hoverImage?: string;
  tagSlot?: React.ReactNode;
};

export const ItemImage = ({
  alt,
  mainImage,
  hoverImage,
  tagSlot,
  className,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div className={cn(styles.ImageBox, className)}>
      {isLoading && <Ghost className={styles.ghostStyles} />}
      {hoverImage && (
        <Image
          className={styles.secondaryImage}
          fill
          onLoadingComplete={() => setIsLoading(false)}
          alt={alt}
          src={hoverImage}
        />
      )}
      {mainImage && (
        <Image fill alt={alt} className={styles.mainImage} src={mainImage} />
      )}

      <div className={styles.tagSlot}>{tagSlot}</div>
    </div>
  );
};
