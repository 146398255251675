import React from 'react';
import { getTags } from '../get-tags.util';
import type { Project } from '@y2/api-clients/yad1';
import styles from './similar-project-item.module.scss';
import { FeedItem } from '../../../components/feed-item/feed-item';
import { ItemTags } from '../../../components/feed-item/item-tags/item-tags';
import { ItemImage } from '../../../components/feed-item/item-image/item-image';
import { pushLikePojectEvent } from '../../analytics/feed-events';
import { useProjectUrl } from '../../../hooks/use-project-url/use-project-url';
import texts from '../../no-search-results/similar-projects/texts.json';

type Props = {
  project: Project;
};

export function SimilarProjectItem({ project }: Props) {
  const projectUrl = useProjectUrl(project.slug, {
    project: project.id.toString(),
    ComponentType: 'map_side_feed',
    ComponentHeader: texts.heading,
  });

  return (
    <div className={styles.similarProjectItem}>
      <FeedItem
        bottomLabel={project.promotionalLabel}
        imageSlot={
          <ItemImage
            mainImage={project.mainImage}
            hoverImage={project.secondaryImage}
            alt={project.projectName}
            tagSlot={<ItemTags tags={getTags(project)} />}
          />
        }
        title={project.projectName}
        subTitle={project.address}
        href={projectUrl}
        likeId={project.id.toString()}
        onLike={() => pushLikePojectEvent('feed', project.id)}
      />
    </div>
  );
}
