import React, { useEffect, useRef } from 'react';
import styles from './base-carousel.module.scss';
import { Swiper } from 'swiper/react';
import cn from 'classnames';
import SwiperCore, { Navigation } from 'swiper';
import { ChevronLeftIcon, ChevronRightIcon } from '@y2/mango/components/icons';

SwiperCore.use([Navigation]);

type Props = {
  swiperProps?: React.ComponentProps<typeof Swiper>;
  navigationProps?: React.HTMLProps<HTMLDivElement>;
  children?: React.ReactNode;
};

export const BaseCarousel = ({
  swiperProps,
  navigationProps,
  children,
}: Props) => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [isReady, setIsReady] = React.useState(false);

  useEffect(() => {
    if (prevRef.current && nextRef.current) {
      setIsReady(true);
    }
  }, [prevRef, nextRef]);

  return (
    <div className={styles.swiperWrapper}>
      <div
        {...navigationProps}
        className={cn(styles.navigationWrapper, navigationProps?.className)}
      >
        <button
          ref={prevRef}
          className={cn(styles.swiperButtonPrev, 'swiper-prev-button')}
        >
          <ChevronRightIcon className={styles.arrowIcon} />
        </button>
        <button
          ref={nextRef}
          className={cn(styles.swiperButtonNext, 'swipe-next-button')}
        >
          <ChevronLeftIcon className={styles.arrowIcon} />
        </button>
      </div>
      <Swiper
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        {...swiperProps}
        className={cn(styles.swiper, swiperProps?.className)}
      >
        {children}
      </Swiper>
    </div>
  );
};
