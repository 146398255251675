import { LngLat } from 'mapbox-gl';

export const MAP_STYLE = 'mapbox://styles/ben-yad2/cm3vr74o500et01r244h12gu7';
export const MAP_TOKEN =
  'pk.eyJ1IjoiYmVuLXlhZDIiLCJhIjoiY2pvd3BzNWVtMXU3YzN2a2ZhajM1bHdydyJ9.8MgG8RpOuIoUWzgIb2qy0g';

export const DEFAULT_CENTER = {
  lng: 34.838167,
  lat: 32.119391,
} as LngLat;
export const MIN_ZOOM = 7;
export const MAX_ZOOM = 18;
export const DEFAULT_ZOOM = 8;
