/* eslint-disable camelcase */
import axios, { AxiosResponse, ResponseBody } from '../http-client';
import env from '@y2/environments';
import { getWithSSRSupport } from '../helpers';
import { cancelTokenProvider } from '../helpers/cancel-token-provider';

export type AddressMasterRequestParams = Partial<{
  topArea: string;
  area: string;
  city: string;
  neighborhood: string;
  street: string;
}>;

export type AutoCompleteRequestParams = Partial<{
  limit: number;
  entities: string;
}>;

export type Pagination = {
  limit: number;
  offset: number;
  total_pages: number;
  current_page: number;
  total_rows: number;
  prev_page: number;
  next_page: number;
};

export type EntitiesBaseFields = {
  type_weight: number;
  title_text: string;
  full_title_text: string;
  popularity: number;
  location: {
    lon: number;
    lat: number;
  };
};

export type TopAreaData = EntitiesBaseFields & {
  top_area_id: number;
  top_area_heb: string;
  top_area_eng: string;
  top_area_lon: number;
  top_area_lat: number;
  top_area_min_x: number;
  top_area_min_y: number;
  top_area_max_x: number;
  top_area_max_y: number;
};

export type AreaData = TopAreaData & {
  area_id: number;
  area_heb: string;
  area_eng: string;
  area_lon: number;
  area_lat: number;
  area_max_x: number;
  area_max_y: number;
  area_min_x: number;
  area_min_y: number;
};

export type CityData = AreaData & {
  city_id: string;
  city_heb: string;
  city_eng: string;
  settlement_type: number;
  city_lon: number;
  city_lat: number;
  city_max_x: number;
  city_max_y: number;
  city_min_x: number;
  city_min_y: number;
};

export type HoodData = CityData & {
  hood_id: number;
  hood_heb: string;
  hood_eng: string;
  hood_lon: number;
  hood_lat: number;
  hood_max_x: number;
  hood_max_y: number;
  hood_min_x: number;
  hood_min_y: number;
};

export type StreetData = CityData & {
  street_id: string;
  street_heb: string;
  street_eng: string;
  street_lon: number;
  street_lat: number;
};

export type AutoCompleteData<Entities> = Record<string, Entities[]>;

export type Response<T> = {
  message: string;
  data: {
    pagination?: Pagination;
    data: [T] | [];
  };
};

const serviceName = 'address-master';

/**
 * client for `address-master` service
 * @see https://gw.yad2.io/address-master/api-docs/#
 */
// eslint-disable-next-line max-lines-per-function
export const addressMasterV1Client = (baseUrl = env.routes.api.gw) => {
  const { withCancelToken } = cancelTokenProvider();

  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });

  return {
    getTopArea: (top_area_id: string, limit = 1) =>
      client.get<Response<TopAreaData>>(`/top-areas`, {
        params: { result_type: 'extended', limit, top_area_id },
      }),
    getArea: (area_id: string, limit = 1) =>
      client.get<Response<AreaData>>(`/areas`, {
        params: {
          result_type: 'extended',
          limit,
          area_id,
        },
      }),
    getCity: (city_id: string, limit = 1) =>
      client.get<Response<CityData>>(`/cities`, {
        params: {
          result_type: 'extended',
          limit,
          city_id,
        },
      }),
    getHood: (hood_id: string, limit = 1) =>
      client.get<Response<HoodData>>(`/hoods`, {
        params: {
          result_type: 'extended',
          limit,
          hood_id,
        },
      }),
    getStreet: (city_id: string, street_id: string) =>
      client.get<Response<StreetData>>(`/streets`, {
        params: {
          result_type: 'extended',
          limit: 1,
          city_id,
          street_id,
        },
      }),
    getAutocomplete: <
      Entities = TopAreaData | AreaData | CityData | HoodData | StreetData,
    >(
      phrase: string,
      options: AutoCompleteRequestParams,
    ) =>
      withCancelToken<AxiosResponse<ResponseBody<AutoCompleteData<Entities>>>>(
        (cancelToken) =>
          client.get(`/autocomplete/${phrase}`, {
            params: options,
            cancelToken,
          }),
      ),
  };
};
