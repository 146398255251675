import { Canceler, CancelToken } from '../http-client';
import { CancelToken as CancelTokenType } from 'axios';

export const cancelTokenProvider = () => {
  let cancel: Canceler | undefined;

  return {
    withCancelToken: <T>(
      requestFn: (cancelToken: CancelTokenType) => Promise<T>,
    ): Promise<T> => {
      if (cancel) cancel();

      return new Promise((resolve, reject) => {
        const cancelToken = new CancelToken(function executor(cancelToken) {
          cancel = cancelToken;
        });

        requestFn(cancelToken).then(resolve).catch(reject);
      });
    },
  };
};
