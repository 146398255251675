import { ItemTag } from '../../components/feed-item/item-tags/item-tags';
import { ThreeDimensionsBoxIcon } from '@y2/mango/components/icons';
import { Project } from '@y2/api-clients/yad1';
import texts from './project-item.texts.json';
import { isAfter, subDays } from 'date-fns';

const fourDays = 4;
const fourDaysAgo = subDays(new Date(), fourDays);

export const getTags = (item: Project): ItemTag[] => {
  const { createdAt, previewType, promotion } = item;

  const hasNewTag = isAfter(new Date(createdAt), fourDaysAgo);

  return [
    hasNewTag && {
      text: texts.new,
      theme: 'white',
    },
    promotion && {
      text: texts.discount,
      theme: 'orange',
    },
    previewType === '3d' && {
      icon: ThreeDimensionsBoxIcon,
      theme: 'dark',
      text: texts['3dDisplay'],
    },
  ].filter(Boolean) as ItemTag[];
};
